import React  from 'react'
import './web_design.css'
import Header from '../header.js'
import Footer from '../footer'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
import Side_menu from '../img_new/our_service_inner/side_menu_logo.png'
import today_contact from '../img_new/our_service_inner/tdycontact.png'
import DocumentMeta from 'react-document-meta';
import {Helmet} from "react-helmet";
// import Helmet from 'react-helmet';
export default class Mobile_and_developments extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
      
    render(){
       /* const meta = {
        title: 'Mobile App Development Company in Texas | Mobile Application Developers in Texas | mobile-application-development',
        description: 'We are one of the best Mobile App Development Company in Texas. Our mobile application developers create iOS & Android apps that are user-friendly.',
        canonical: 'https://www.icoresoftwaresystems.com/mobile-application-development',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Technology-driven, Problem-solving, Excellence, Customer-focus, Accountability'
            }
        }
    }*/
        return(    
            /*<DocumentMeta {...meta}/>*/
            <div>
                <Helmet>
                   <meta charSet="utf-8" />
                   <title>Mobile App Development Company in Texas | Mobile Application Developers in Texas | mobile-application-development</title>
                   <meta name="description" content="We are one of the best Mobile App Development Company in Texas. Our mobile application developers create iOS & Android apps that are user-friendly." />
                   <link rel="canonical" href="https://www.icoresoftwaresystems.com/mobile-application-development" />
                </Helmet>
            <Header/>
            <div className="container-fluid">
                <div className="row">
                    <div className={ 'mobile_app_bg_drop'}>
                    </div>
        
                    <div className="col-lg-12">
                        <div className="col-lg-8 banner_img">
                            <div className={ 'marg_all_heading'}>
                                <h2 className="about_ser_new_tags">Mobile application development</h2>
                                <span className="about_bor_bot_serv"></span>
                            </div>
                            <div className="aft_heading_para">
                                <p>
                                iCore Software Systems provides world-class mobile application development services that cater to all your business needs. Our team of expert developers specializes in building robust, scalable, and intuitive mobile apps for iOS, Android, and Hybrid platforms. Whether you need a native app or a cross-platform solution, we ensure high performance and seamless user experiences.</p>
                                {/* <p>
                                Transform your heritage systems to flexible, modular applications to improve your products’ time-to-market and invite customer loyalty. Our experts apply cutting-edge research to deliver enterprise alignment, drive customer engagement, augment profits, and achieve your business objectives.</p>
                                <p id={'android-app'}>
                                Developing robust mobile apps, we also offer maintenance and security across a strong, and reliable architecture.
                                Notice how a typical mobile application utilizes a network connection to operate with remote computing resources? As reputed mobile application developers in Texas, our experts create mobile applications that can exist on both Android and iOS platforms. 
                                </p>
                                <p >
                                These include applications developed for PDAs (Personal Digital Assistants) in mobile devices, enterprise digital assistants, or the mobile phone itself. While some of these are pre-installed in the mobiles, others can be downloaded from the play store.
                                </p> */}
                            </div>
                            
        
                            <div className="aft_heading_para" id={'/android-app'}>
                                <h3 className=" txt_bor_align_about_inner1">Android app development</h3>
                                <p>
                                    iCore Software Systems offers top-notch Android app development services to create powerful, user-friendly, and secure apps that work seamlessly across the entire Android ecosystem. Our team uses the latest tools like Kotlin, Java, and Android Studio to develop custom apps that meet your business needs.
                                </p>
                                    <li className='mobile_drop-list-item'>Custom Android app development</li>
                                    <li className='mobile_drop-list-item'>Enterprise Android solutions</li>
                                    <li className='mobile_drop-list-item'>Android app UI/UX design</li>
                                    <li className='mobile_drop-list-item'>Android app testing and support</li>

                                {/* <p>
                                By adopting an agile approach, our android app development services are fully customized to obtain the most appropriate output. We delve deep into the android spectrum and explore all possible options before we arrive at a solution. Having gained a reputation as being one of the most reliable providers, we ensure that you have a solid solution that works across every device, regardless of the size, industry, or complexity of the project. Experts at iCore Software Systems offer scalable, fully functional android apps optimally customized to garner that much-needed competitive edge. 
                                </p> */}
                                
                            </div>
                            <div className="aft_heading_para" id={'/ios-app'}>
                                <h3 className=" txt_bor_align_about_inner1">iOS App development</h3>
                              <p>
                              With our iOS app development services, you can reach millions of iPhone and iPad users with high-quality apps designed for Apple's iOS platform. Our developers are proficient in Swift, Objective-C, and Xcode, delivering secure and scalable iOS apps for businesses of all sizes.
                             </p>
                                <li className='mobile_drop-list-item'>Native iOS app development</li>
                                <li className='mobile_drop-list-item'>iPhone and iPad app development</li>
                                <li className='mobile_drop-list-item'>Custom iOS solutions for businesses</li>
                                <li className='mobile_drop-list-item'>iOS app maintenance and optimization</li>
                              
                            </div>
        
                            <div className="aft_heading_para" id={'/hybrid-app'}>
                                <h3 className=" txt_bor_align_about_inner1">Hybrid mobile application</h3>
                                <p>For businesses looking to build mobile apps that work on multiple platforms, our hybrid mobile app development services offer the perfect solution. We use frameworks like React Native, Flutter, and Xamarin to create apps that deliver near-native performance while reducing development time and costs.</p>
                                <li className='mobile_drop-list-item'>Cross-platform mobile app development</li>
                                <li className='mobile_drop-list-item'>Hybrid app UI/UX design</li>
                                <li className='mobile_drop-list-item'>Native-like performance on iOS and Android</li>
                                <li className='mobile_drop-list-item'>Cost-effective and time-saving solutions</li>
                                
                            </div>
                            <div className="aft_heading_para" style={{marginTop:"5%"}}>
                                <p>Are you ready to take your business mobile? Contact iCore Software Systems today for a free consultation, and let’s build a custom mobile app that accelerates your business success.</p>
                                </div>
        
                        </div>
                        <div className="col-lg-4">
                            <div className="overall_service">
                                <div className="custom_border_service">
                                    <ul className="custom_ul_service">
                                        <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/web-application-development">Web application development</Link>
                                        </li>
                                        <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/mobile-application-development"> Mobile application development</Link>
                                        </li>
                                        <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/our-niche">Our Niche solutions</Link>
                                        </li>
                                        <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/digital-marketing">Digital marketing services</Link>
                                        </li>
                                        {/* <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/web-in-Aus">Web application development in Australia</Link>
                                        </li> */}
                                        {/* <li className="custom_li_service">
                                            <span className="custom_icon_service">
                                                      <img src={Side_menu} className="custom_img_service"></img>
                                                  </span>
                                            <Link to="/mobile-in-Aus">Mobile application development in Australia</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br/>
            <div className="today_contact">
                <img id="img_logo" alt="not found" src={today_contact}/>
                <h3 className="chk">Ready For Your Next IT Project?</h3>
                <p className="tdy_p">Collaborate With Us You Will Be Satisfied</p>
                <Link to="/contact-us"  target="_top">
                <button type="button" className="btn btn-primary btn-sm" id="tdy_button">Contact Us Today</button></Link>
            </div>
            <Footer/>
        </div>
        )
    }
}